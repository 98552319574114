<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-briefcase-account-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.numeroArquivoInterno"
                    label="Núm. Arquivo Interno"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="8" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.nomeFantasia"
                    label="Nome Fantasia"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="9" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.nomeRazao"
                    label="Nome/Razão Social"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.cpfCnpj"
                    label="CPF/CNPJ"
                    v-mask="metMaskCpfCnpj(varProprietarioLocal.cpfCnpj)"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.identidade"
                    label="Identidade"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.cuit"
                    label="CUIT"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.rut"
                    label="RUT"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.pis"
                    label="PIS"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.numeroMatricula"
                    label="Matrícula"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    type="date"
                    hide-details="auto"
                    v-model="varProprietarioLocal.dataMatricula"
                    label="Data Matrícula"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.apolice"
                    label="Apólice"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    type="date"
                    hide-details="auto"
                    v-model="varProprietarioLocal.vencimentoApolice"
                    label="Vencimento Apólice"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.permisso"
                    label="Permisso"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    type="date"
                    hide-details="auto"
                    v-model="varProprietarioLocal.vencimentoPermisso"
                    label="Vencimento Permisso"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.permissoComplementar"
                    label="Permisso Complementar"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    type="date"
                    hide-details="auto"
                    v-model="
                      varProprietarioLocal.vencimentoPermissoComplementar
                    "
                    label="Vencimento Permisso Complementar"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.cep"
                    label="CEP"
                    v-mask="'#####-###'"
                    outlined
                    @blur="metObterDadosCep(varProprietarioLocal.cep)"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="8" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.endereco"
                    label="Endereço"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.numero"
                    label="Número"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.complemento"
                    label="Complemento"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.bairro"
                    label="Bairro"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="5" align-self="center">
                  <v-autocomplete
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.idPais"
                    label="País"
                    :items="compPaisesSelect"
                    item-text="nome_pt"
                    item-value="id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="auto" md="5" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.cidade"
                    label="Cidade"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.uf"
                    label="UF"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.email"
                    label="E-mail"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.telefone"
                    label="Telefone"
                    v-mask="metMaskFone(varProprietarioLocal.telefone)"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="12" align-self="center">
                  <v-textarea
                    dense
                    hide-details="auto"
                    v-model="varProprietarioLocal.observacoes"
                    label="Observações"
                    rows="2"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";
import axios from "../_services/axios";

const { mapState, mapActions } = createNamespacedHelpers("cadProprietarios");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   varProprietarioLocal: {
  //     nome_proprietario: { required },
  //     email: { required, email },
  //     proprietario: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "CadProprietariosForm",
    tituloForm: "",
    varProprietarioLocal: {},
  }),

  computed: {
    ...mapState(["staProprietarioSelect", "staPaisesSelect"]),

    compPaisesSelect() {
      return this.staPaisesSelect;
    },
  },

  watch: {
    /* staProprietarioSelect(proprietarioNovo) {
      this.metSincronizar(proprietarioNovo);
    }, */
  },

  created() {
    this.metSincronizar(this.staProprietarioSelect);
    if (this.staProprietarioSelect) {
      this.tituloForm = "Editar Proprietário";
    } else {
      this.tituloForm = "Nova Proprietário";
    }
    this.actPaisesSelect();
    this.metDatasDB();
  },

  methods: {
    ...mapActions([
      "actSelecionarProprietario",
      "actResetarProprietario",
      "actCriarProprietario",
      "actEditarProprietario",
      "actResetarErro",
      "actPaisesSelect",
    ]),

    async metObterDadosCep(cep) {
      if (cep) {
        try {
          var dadosCep = await axios.getObterDadosCep(cep);
          this.varProprietarioLocal.endereco =
            dadosCep.data.tipo_logradouro + " " + dadosCep.data.nome_logradouro;
          this.varProprietarioLocal.bairro = dadosCep.data.bairro;
          this.varProprietarioLocal.cidade = dadosCep.data.nome_localidade;
          this.varProprietarioLocal.uf = dadosCep.data.uf;
        } catch (err) {
          console.log(err);
        }
      }
    },

    metMaskFone(fone) {
      if (fone == null) {
        return "";
      }
      if (fone.includes("(")) {
        return fone.length <= 13 ? "(##)####-####" : "(##)# ####-####";
      } else {
        return fone.length <= 10 ? "(##)####-####" : "(##)# ####-####";
      }
    },

    selecionarProprietarioEdicao(proprietario) {
      this.actSelecionarProprietario({ proprietario });
    },

    async salvarProprietario() {
      switch (this.staProprietarioSelect) {
        case undefined:
          this.varProprietarioLocal.criacao =
            this.$store.state.login.usuario.usuario;
          this.varProprietarioLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          await this.actCriarProprietario({
            proprietario: this.varProprietarioLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.cadProprietarios.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadProprietarios.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Proprietário";
            this.$root.snackbar.show({
              type: "success",
              message: "Proprietário Criado!",
            });
          }
          break;
        default:
          this.varProprietarioLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varProprietarioLocal.criado = undefined;
          this.varProprietarioLocal.criacao = undefined;
          this.varProprietarioLocal.modificado = undefined;
          await this.actEditarProprietario({
            proprietario: this.varProprietarioLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.cadProprietarios.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadProprietarios.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Proprietário Salvo!",
            });
          }
      }
      //this.resetar();
    },

    metDatasDB() {
      this.varProprietarioLocal.dataMatricula = this.mixDataDBDCal(
        this.varProprietarioLocal.dataMatricula
      );
      this.varProprietarioLocal.vencimentoApolice = this.mixDataDBDCal(
        this.varProprietarioLocal.vencimentoApolice
      );
      this.varProprietarioLocal.vencimentoPermisso = this.mixDataDBDCal(
        this.varProprietarioLocal.vencimentoPermisso
      );
      this.varProprietarioLocal.vencimentoPermissoComplementar =
        this.mixDataDBDCal(
          this.varProprietarioLocal.vencimentoPermissoComplementar
        );
    },

    metMaskCpfCnpj(n) {
      if (n == null) {
        return "";
      }
      return n.length <= 14 ? "###.###.###-##" : "##.###.###/####-##";
    },

    metSincronizar(proprietario) {
      this.varProprietarioLocal = Object.assign(
        {},
        proprietario || {
          id: null,
          endereco: null,
          bairro: null,
          cidade: null,
          uf: null,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarProprietario();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varProprietarioLocal.ultima_compra = this.staProprietarioSelect.ultima_compra;
      // this.varProprietarioLocal.email = this.staProprietarioSelect.email;
      // this.varProprietarioLocal.proprietario = this.staProprietarioSelect.proprietario;
      // this.varProprietarioLocal.senha = this.staProprietarioSelect.senha;
      // this.varProprietarioLocal.ativo = this.staProprietarioSelect.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
